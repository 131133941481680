import React from 'react';
import './style.css';

function Contact() {

    return (

        <div className="page"></div>

    );

};

export default Contact;